@import url(https://fonts.googleapis.com/css?family=BioRhyme|Montserrat);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root{
  --feature: rgba(25,176,188);
  --doable: rgba(0, 199, 66, 0.5);
  --notdoable: rgba(0, 0, 0, 0.2);
  --blackground: #282c34;
  --offWhite: rgb(224, 224, 224);
  --fadedWhite: rgba(224, 224, 224, 0.5);
  --semiTransparent: rgba(0, 0, 0, 0.1);
  --notVeryTransparent: rgba(0,0,0,0.3);
   font-size: calc(10px + 2vmin);
   
}

body{
color: rgb(224, 224, 224);
color: var(--offWhite);
background-image: -webkit-linear-gradient(top, #09203f 0%, #537895 100%);
background-image: linear-gradient(to bottom, #09203f 0%, #537895 100%);
font-family: 'Montserrat', sans-serif;

}
section{
  margin: 50px;
}
h2{
  font-family: 'BioRhyme', 'Courier New', Courier, monospace;
}
.logo{
  color:rgba(25,176,188);
  color:var(--feature);
  font-family: 'BioRhyme', 'Courier New', Courier, monospace;
}
.small_text{
  font-size: 0.8rem;
  line-height: 0;
}
.App {
  text-align: center;
  min-height: 100vh;
}
.App-header {
  background: rgba(0,0,0,0.3);
  background: var(--notVeryTransparent); 
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.landing_section{
  font-size: 1.2rem;
}
.landing_input{
  background: rgba(0, 0, 0, 0.1);
  background: var(--semiTransparent);
  border: 0px;
  font-size: 1.2rem;
  text-align: center;
  min-width: 60px;
  border: 1px solid rgb(224, 224, 224);
  border: 1px solid var(--offWhite);
  color: rgb(224, 224, 224);
  color: var(--offWhite);
  font-family: 'Montserrat', sans-serif;
}

.landing_button {
  background: rgba(0, 0, 0, 0.1);
  background: var(--semiTransparent);
  color: rgb(224, 224, 224);
  color: var(--offWhite);
  border: 1px solid rgb(224, 224, 224);
  border: 1px solid var(--offWhite);
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  padding: 10px;
  margin: 0px 30px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s
}

.landing_button:hover {
  background: rgba(25,176,188);
  background: var(--feature);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.transparent {
  color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  border: rgba(0, 0, 0, 0);
}
.transparent:hover {
  color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  border: rgba(0, 0, 0, 0);
}


.app_inverseButton {
  background: rgba(0, 0, 0, 0.1);
  background: var(--semiTransparent);
  border: 1px solid rgb(224, 224, 224);
  border: 1px solid var(--offWhite);
  font-size: 0.5rem;
  padding: 10px;
  color: rgb(224, 224, 224);
  color: var(--offWhite);
  text-transform: uppercase;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.app_inverseButton:hover {
  background: rgb(224, 224, 224);
  background: var(--offWhite);
  color: #282c34;
  color: var(--blackground);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.calendar_calendarBox{
  color: rgba(224, 224, 224, 0.5);
  color: var(--fadedWhite);
  display:grid;
  grid-template-columns: repeat(7, 12%);
  grid-template-rows: 50px 30px;
  grid-auto-rows: 40px;
  min-width: 300px;
  max-width: 600px;
  width:70%;
  margin: 0 auto;
  justify-content: space-between;
  text-align: center;
  text-transform: lowercase;
  border: 1px solid rgba(224, 224, 224, 0.5);
  border: 1px solid var(--fadedWhite);
  margin-bottom: 50px;
}
.calendar_monthTitle{
  grid-column: span 7;
  background: rgba(0, 0, 0, 0.1);
  background: var(--semiTransparent);
  color: rgba(25,176,188);
  color: var(--feature);
  font-family: 'BioRhyme', 'Courier New', Courier, monospace;
  padding-bottom: 5px;
  font-size: 1rem;
}
.calendar_dayTitle{
  font-size: 0.7rem;
}
.day_dayBox {
  margin: 0 auto;
  width: 40px;
  height: 100%;
}
.day_dayButton{
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.1);
  background: var(--semiTransparent);
  border: 2px solid rgba(224, 224, 224, 0.5);
  border: 2px solid var(--fadedWhite);
  color: rgb(224, 224, 224);
  color: var(--offWhite);
  height: 90%;
  width: 90%;
  font-size: 0.6rem;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.day_doable{
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: rgba(25,176,188);
  background: var(--feature);
  color: rgb(224, 224, 224);
  color: var(--offWhite);
  border: 2px solid rgb(224, 224, 224);
  border: 2px solid var(--offWhite);
}
